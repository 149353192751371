<template>
  <v-container fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm12 md10 lg8 xl6>
        <v-form class="elevation-5">
          <template>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-counter</v-icon>
              <v-toolbar-title>{{ tituloForm }}</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metClear"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
          </template>

          <template>
            <v-container fluid>
              <v-row dense>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varNumeracaoLocal.id"
                    label="ID"
                    readonly
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varNumeracaoLocal.numero"
                    label="Número"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="6" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varNumeracaoLocal.tipo"
                    label="Tipo"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    type="date"
                    hide-details="auto"
                    v-model="varNumeracaoLocal.data"
                    label="Data"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4 mr-4 mb-4"
                  color="green darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="submit"
                >
                  mdi-check-circle
                </v-icon>
              </template>
              <span>Salvar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-4 mb-4"
                  color="red darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="metVoltar"
                >
                  mdi-backspace
                </v-icon>
              </template>
              <span>Cancelar</span>
            </v-tooltip>
          </template>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { validationMixin } from "vuelidate";
// import { required, email } from "vuelidate/lib/validators";
import DatasMixin from "@/mixins/DatasMixin";

const { mapState, mapActions } = createNamespacedHelpers("cadNumeracoes");

export default {
  mixins: [validationMixin, DatasMixin],
  // components: { tableCompras },
  // validations: {
  //   varNumeracaoLocal: {
  //     nome_numeracao: { required },
  //     email: { required, email },
  //     numeracao: { required },
  //     senha: { required },
  //     ativo: { required },
  //   },
  // },

  data: () => ({
    name: "CadNumeracoesForm",
    tituloForm: "",
    varNumeracaoLocal: {},
  }),

  computed: {
    ...mapState(["staNumeracaoSelect"]),
  },

  watch: {
    staNumeracaoSelect(numeracaoNovo) {
      this.metSincronizar(numeracaoNovo);
    },
  },

  created() {
    this.metSincronizar(this.staNumeracaoSelect);
    if (this.staNumeracaoSelect) {
      this.tituloForm = "Editar Numeração";
    } else {
      this.tituloForm = "Novo Numeração";
    }
    this.metDatasDB();
  },

  methods: {
    ...mapActions([
      "actSelecionarNumeracao",
      "actResetarNumeracao",
      "actCriarNumeracao",
      "actEditarNumeracao",
      "actResetarErro",
    ]),

    selecionarNumeracaoEdicao(numeracao) {
      this.actSelecionarNumeracao({ numeracao });
    },

    async salvarNumeracao() {
      switch (this.staNumeracaoSelect) {
        case undefined:
          this.varNumeracaoLocal.criacao =
            this.$store.state.login.usuario.usuario;
          this.varNumeracaoLocal.modificacao =
            this.$store.state.login.usuario.usuario;
          await this.actCriarNumeracao({
            numeracao: this.varNumeracaoLocal,
            licenca: this.$store.state.login.licenca,
          });

          if (this.$store.state.cadNumeracoes.staErro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.cadNumeracoes.staErro,
            });
            this.actResetarErro();
          } else {
            this.tituloForm = "Editar Numeracao";
            this.$root.snackbar.show({
              type: "success",
              message: "Numeracao Criada!",
            });
          }
          break;
        default:
          this.varNumeracaoLocal.modificacao =
            this.$store.state.login.usuario.usuario;
          this.varNumeracaoLocal.criado = undefined;
          this.varNumeracaoLocal.criacao = undefined;
          this.varNumeracaoLocal.modificado = undefined;
          await this.actEditarNumeracao({
            numeracao: this.varNumeracaoLocal,
            licenca: this.$store.state.login.licenca,
          });
          // this.formataDatasDB();
          if (this.$store.state.cadNumeracoes.staErro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.cadNumeracoes.staErro,
            });
            this.actResetarErro();
          } else {
            this.$root.snackbar.show({
              type: "success",
              message: "Numeracao Salva!",
            });
          }
      }
      //this.resetar();
    },

    metDatasDB() {
      this.varNumeracaoLocal.data = this.mixDataDBDCal(
        this.varNumeracaoLocal.data
      );
    },

    metSincronizar(numeracao) {
      this.varNumeracaoLocal = Object.assign({}, numeracao || { id: null });
    },
    submit() {
      // this.$v.$touch();
      this.salvarNumeracao();
      // this.$router.back();
    },
    metClear() {
      //this.$v.$reset();
      // this.varNumeracaoLocal.ultima_compra = this.staNumeracaoSelect.ultima_compra;
      // this.varNumeracaoLocal.email = this.staNumeracaoSelect.email;
      // this.varNumeracaoLocal.numeracao = this.staNumeracaoSelect.numeracao;
      // this.varNumeracaoLocal.senha = this.staNumeracaoSelect.senha;
      // this.varNumeracaoLocal.ativo = this.staNumeracaoSelect.ativo;
    },
    metVoltar() {
      this.$router.back();
    },
  },
};
</script>
